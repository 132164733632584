

.bundle-card
    position relative
    // border: 1px solid $color-bnn-gray-light
    background $color-bnn-gray-light-2 0% 0% no-repeat padding-box
    border-radius: 8px;
    display: flex
    justify-content: center
    flex-wrap: wrap
    gap: 16px 0;
    // grid-template-columns: repeat(1, 1fr);
    height: 245px;
    padding 1rem 0.05rem
    &:hover
        cursor pointer
    img {
        max-width:100px;
        max-height:100px;
        width: 100%
        height: 100%
        object-fit: contain
    }
    div {
        font-size: 0.75rem
        width 100%
    }
	.bundle-button
		padding: 8px

.bundle-price {
    font-size: 0.9rem
    font-weight: bold
    color: $color-text-price
    margin-top: -1rem
    padding-left: 1rem
    text-align: left
    bottom: 0
    left: 0
}

.bundle-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.35rem;
    text-align: left
    padding: 0 1rem;
}

.bundle-card.select
    border: 1px solid $color-bnn-gray-medium;

.freebies-by-coupon
	.bundle-card
		max-width: 100%
		height: fit-content
		padding 1rem
		gap: 16px
		&:hover
			cursor pointer
		img {
			max-width: 100%
			max-height: 100%
			width: 100%
			height: 100%
			object-fit: contain
		}
		.bundle-price
			height: fit-content
			font-size: 16px
			font-weight: bold
			line-height: 1
			padding: 0
		.bundle-name
			height: 28px
			font-size: 14px
			font-weight: normal
			line-height: 1
			padding: 0
		.bundle-button
			padding: 0
