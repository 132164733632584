

.swiper-bundle {
    padding-left: 1.8rem
    padding-right: 1.8rem
    background white
}

.angle-icon {
    width: 28px
    height: 28px
    background: $color-bnn-gray-medium-2
    border-radius: 50px
    display: flex
    justify-content: center
    align-items: center
}

.swiper {
  height: 350px;
  width: 100%;

  &.freebies-by-coupon {
	height: fit-content;
	.swiper-slide {
		display: unset
		position: relative
	}
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    background: none
  }
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left 0 !important
  position : absolute
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right 0 !important
}

.swiper-button-next::after, .swiper-button-prev::after {
    content: "" !important
}

.swiper-button-next, .swiper-button-prev {
    color: white !important
    width auto !important
}

.swiper-button-prev,
.swiper-button-next
	transition: 0.2s ease-in-out !important
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled
	transition: 0.2s ease-in-out !important
